// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advance-success-js": () => import("./../../../src/pages/advance-success.js" /* webpackChunkName: "component---src-pages-advance-success-js" */),
  "component---src-pages-basic-success-js": () => import("./../../../src/pages/basic-success.js" /* webpackChunkName: "component---src-pages-basic-success-js" */),
  "component---src-pages-donation-js": () => import("./../../../src/pages/donation.js" /* webpackChunkName: "component---src-pages-donation-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-feature-request-js": () => import("./../../../src/pages/feature-request.js" /* webpackChunkName: "component---src-pages-feature-request-js" */),
  "component---src-pages-help-us-improve-js": () => import("./../../../src/pages/help-us-improve.js" /* webpackChunkName: "component---src-pages-help-us-improve-js" */),
  "component---src-pages-how-to-use-js": () => import("./../../../src/pages/how-to-use.js" /* webpackChunkName: "component---src-pages-how-to-use-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-temp-excel-file-js": () => import("./../../../src/pages/temp-excel-file.js" /* webpackChunkName: "component---src-pages-temp-excel-file-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-transaction-success-js": () => import("./../../../src/pages/transaction-success.js" /* webpackChunkName: "component---src-pages-transaction-success-js" */)
}

